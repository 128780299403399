module.exports = {
    menus: {
        home: 'Home',
        download: 'Download',
        buy: 'Buy',
        service: 'Service',
        blog: 'Blog',
        support: 'Support'
    },
    login_text: "Sign In",
    logout_text: "Log Out",
    login_success: "Logged in successfully!",
    reset_password: "Reset Password",
    change_email: "Change Email",
    send_text: "Get Code",
    send_successfully: "Send successfully, check your email.",
    create_account: "Create an account",
    connect_world: "Connect with the world",
    register: "Sign Up",
    register_successfully: "Register successfully",
    reset_pwd_successfully: "Reset Password successfully",
    change_email_successfully: "Change email successfully",
    back_to_home: "Back to Homepage",
    gift_card_text: "Squirrel Gift Card",
    apply_invite_code_text: "Apply Invitation Code",
    node_list_text: "Node List",
    account_info: "Account",
    reg_account: "Account Email",
    expire_time: "Expire Time",
    copy: "Copy",
    copy_successfully: "Copy successfully",
    qrcode: "QRCode",
    submit: "Submit",
    invitation_code: "Invitation Code",
    redeem_text: "Redeem",
    redeem_success_title: "Redeemed Successfully!",
    redeem_success_sub_title: "Please check the latest expiration date in your SquirrelVPN App.",
    register_link: "No account？Sign Up",
    register_tip: "Please sign up on SquirrelVPN mobile App.",
    subscribe: {
        data_quota: 'Quota',
        data_balance: 'Balance',
        data_download: 'Download',
        data_upload: 'Upload',
        subscribe_url: 'Subscribe URL',
        reset: 'Reset',
        reset_url: 'Reset Subscribe URL',
        reset_tip:'After the reset, the original subscription link will be invalid and need to be re-imported. Do you want to reset it?',
        backup_nodes: 'Backup Nodes'
    },
    product_section: {
        title1: 'SquirrelVPN:',
        title2: 'The First Decentralized VPN',
        subtitle: 'Enjoy the true internet, your last VPN service',
        btn_buy: 'Buy',
        btn_download: 'Download',
    },
    technology_section: {
        title: 'Technology',
        text1: '⚡️SquirrelVPN is a secure VPN powered by a decentralized tokenized P2P network potentially allowing your own nodes to join to do bandwidth mining.',
        text2: 'The underlying blockchain system is under development to tokenize your bandwidth contribution.💥',
        text3: '🌈Eventually, SquirrelVPN will be the Airbnb version of bandwidth market that delivers highly secure and private internet access with low cost.'
    },
    services_section: {
        title: 'VPN Services',
        class_name: 'vpn_features_image_EN'
    },
    download_section: {
        title: 'How To Download',
        device: '01. Mobile Apps',
        size: 'File size: 20.5M',
        pro_description: 'Pro version includes In-App VPN purchase feature.',
        pro_description2: 'The prices are the same as our website.',
        app_store_modal_title: 'Dear IOS customer: ',
        app_store_modal_ok: 'Got it!',
        modal_cancel: 'Cancel',
        apple_id: 'How to Switch Apple Store',
        register_apple_id: 'How to register Apple ID (US)',
        device2: '02. Desktop Apps',
        description1: 'Please make sure you have a SquirrelVPN account, ',
        description2: 'you can only SIGN UP in our mobile app.',
        size_mac: 'File size: 34M',
        support_mac: 'MacOS 10.14​ +',
        size_win_store: 'File size: 45M',
        size_win: 'File size: 19M',
        support_win_store: 'Win10 or above & 64 bit only',
        support_win: 'Win7 or above & 64 bit only',
        win7_updater_reminder: 'Win7 is not working? Install SP1 + Root Certificates',
        size_linux: 'File size: 36M',
        support_linux: 'Ubuntu 16+, other Linux OS not verified',
        tip_linux: 'Please grant SquirrelVPN execution permission',
        linux_tooltip_title: 'How to allow executing a file on Linux',
        linux_tooltip_body: 'After downloaded the Linux version, right click on the file, go to “Properties“ > ”Permissions”, check “Allow executing file as program”',
        device3: '03. Smart TV Apps',
        size_TV: 'File size: 30M',
        tv_apk_blog: 'How to send apk file to your Smart TV?',
        descriptionTV: 'Please make sure you have a SquirrelVPN account, ',
        descriptionTV2: 'you can only SIGN UP in our mobile app.',
        android_not_working: 'Android is not working?'
    },
    buy_section: {
        title: 'Buy VPN Service'
    },
    support_section: {
        title: 'Support',
        subtitle1: 'Contact Us',
        subtitle2: 'Community',
        subtitle3: 'Tutorials',
        email: 'Email: ',
        telegram1: 'Tech Support：',
        telegram2: 'Notices & Updates: ',
        text: 'Backup download address: ',
        faq: 'FAQ',
        tutorial1: 'How to Switch Apple Store',
        tutorial2: 'How to register Apple ID (US)',
        tutorial3: 'How to register Apple ID (Hong Kong)',
        tutorial4: 'How to buy USDT crypto with RMB',
    },
    payment_modal: {
        detail3: ' Support 4 devices online at the same time',
        detail2: ' VIP Networks, faster and more stable',
        detail1: ' Worldwide servers, unlimited data',
        premium: 'Go Premium:',
        total: 'Total:',
        select: 'Select Payment:',
        alipay: 'Alipay',
        credit: 'Credit Card',
        paypal: 'PayPal',
        apple_pay: 'Apple Pay',
        apple_pay_fee_alert: 'Recommend using other payment methods, Alipay gateway charges additional 15% processing fee.',
        google_pay: 'Google Pay',
        crypto: 'Crypto',
        crypto_recommend: 'Proceed to Pay',
        crypto_coinpayment: 'Proceed to CoinPayment',
        crypto_tuturial: 'Have no crypto yet? Learn how',
        crypto_tuturial_2: 'Buy SquirrelVPN with crypto',
        crypto_choose: 'Choose Crypto type',
        crypto_choose_2: 'Pay with Crypto Currency',
        crypto_main: 'Top ranked cryptos:',
        crypto_others: 'Other cryptos:',
        crypto_order_timeout_title: "Payment Timeout!",
        crypto_order_success_title: "Payment Successfully!",
        crypto_order_canceled_title: "Payment Canceled!",
        crypto_order_timeout: "If you have paid, please wait a while (at most 20 minutes); if not, please go back and start a new order.",
        crypto_order_success: "Your VPN purchase will be valid to your SquirrelVPN account.",
        crypto_order_canceled: "Please go back and start a new order.",
        crypto_type: "Crypto Type:",
        crypto_price: "Price:",
        crypto_amount: "Amount:",
        address: "Address:",
        time_left: "Time left:",
        copy: "Copy",
        crypto_steps_title: "Steps:",
        crypto_steps_1: "1. Send the correct amount of cryptos with your wallet or exchange.",
        crypto_steps_2: "2. Please wait for 15-25 minutes after sent, your purchased will be valid automatically then. The current page will update your payment status automatically.",
        crypto_notice_title: "Notice:",
        crypto_notice_1_1: "1. Please send ",
        crypto_notice_1_2: "exactely the same amount (even the numbers after the decimals) ",
        crypto_notice_1_3: "as listed above. Contact support if you sent a wrong amount.",
        crypto_notice_2_1: "2. Don't send partial payment, please ",
        crypto_notice_2_2: "send the entire amount ",
        crypto_notice_2_3: "of cryptos at once.",
        crypto_notice_3: "3. Don't send another type of crypto to the address, this cannot be reverted.",
        crypto_notice_4: "4. Contact support if you have any questions making payments: ",
        crypto_stable: 'Stable cryptos:',
        crypto_stable_subtitle: "Lowest fee"
    },
    payment_results: {
        success: 'Purchased Successfully!',
        error: 'Internal Server Error!',
        error_details: 'Please contact Customer Support.',
        success_details: 'Your purchase will be valid to your SquirrelVPN account in 5 minutes.'
    }

};
