module.exports = {
    menus: {
        home: '首页',
        download: '下载',
        buy: '购买',
        service: '服务',
        blog: '博客',
        support: '支持'
    },
    login_text: "登录",
    logout_text: "安全退出",
    login_success: "登录成功！",
    reset_password: "重置密码",
    change_email: "更换邮箱",
    send_text: "获取验证码",
    send_successfully: "发送成功, 请检查邮箱",
    create_account: "创建松鼠账号",
    connect_world: "连接世界，畅享真实的互联网",
    register: "注册",
    register_successfully: "注册成功",
    reset_pwd_successfully: "重置密码成功",
    change_email_successfully: "更换邮箱成功",
    back_to_home: "回到松鼠首页",
    gift_card_text: "松鼠礼品卡",
    apply_invite_code_text: "填写邀请码",
    node_list_text: "节点列表",
    account_info: "账号信息",
    reg_account: "会员账号",
    expire_time: "到期时间",
    copy: "复制",
    copy_successfully: "复制成功",
    qrcode: "二维码",
    submit: "提交",
    invitation_code: "邀请码",
    redeem_text: "确认充值",
    redeem_success_title: "充值成功!",
    redeem_success_sub_title: "请在SquirrelVPN App上查看服务到期时间！",
    register_link: "还没有账号？注册",
    register_tip: "请在松鼠VPN手机App上注册。",
    subscribe: {
        data_quota: '流量总额',
        data_balance: '剩余流量',
        data_download: '下载流量',
        data_upload: '上传流量',
        subscribe_url: '订阅链接',
        reset: '重置',
        reset_url: '重置订阅链接',
        reset_tip:'重置后原订阅链接将失效，需要重新导入，是否重置？',
        backup_nodes: '备用节点'
    },
    product_section: {
        title1: '松鼠VPN:',
        title2: '全球首款去中心化的VPN',
        subtitle: '畅享真实的互联网！我们来自美国硅谷，您的最后一款VPN',
        btn_buy: '购买',
        btn_download: '下载',
    },
    technology_section: {
        title: '创新的技术',
        text1: '⚡️松鼠VPN是首款基于端对端网络的去中心化VPN，安全、稳定、封不住：',
        text2: '传统的VPN都是固定的服务器和IP地址，一旦被封锁服务就中断。然而，松鼠VPN背后是一个创新的端对端网络，💥可以动态查找节点，按需求加减节点，均衡负载，IP也不固定。只要能查到一个可用节点就能访问整个端对端网络。所以，几乎没有可能被封锁，只要还有一个节点能找到，咱们的服务就不会受到影响。',
        text3: '🌈底层的区块链系统正在开发中，届时个人计算机也可以作为节点加入网络进行带宽挖矿、帮助代理或者转发，赢取相应数量的数字货币。节点越多，网络越稳定，价格越低。最终，松鼠VPN将成为Airbnb版的带宽市场，提供安全、低价的私人网络服务。'
    },
    services_section: {
        title: 'VPN 服务',
        class_name: 'vpn_features_image_CN'
    },
    download_section: {
        title: '如何下载',
        device: '01. 下载手机端',
        size: '文件大小: 20.5M',
        pro_description: 'Pro版本内置了购买功能, 与官网价格一致。',
        pro_description2: '建议按月付费用户下载。',
        app_store_description2: '其他功能与pro版一致, 建议年费用户下载。',
        app_store_modal_title: '尊敬的IOS用户：',
        app_store_modal_ok: '知道了',
        modal_cancel: '取消',
        apple_id: '切换苹果商店教程',
        register_apple_id: '注册苹果ID教程(美国版)',
        device2: '02. 下载电脑端',
        description1: '下载电脑端之前，请确认您已经在松鼠VPN手机端注册了账户。仅支持在手机端注册，电脑端只能登录。',
        size_mac: '文件大小: 34M',
        support_mac: '支持MacOS 10.14​以上',
        size_win_store: '文件大小: 45M',
        size_win: '文件大小: 19M',
        support_win_store: 'Win10及以上，仅限64位系统',
        support_win: 'Win7及以上，仅限64位系统',
        win7_updater_reminder: 'Win7无法上网？请安装：SP1 + 根证书',
        size_linux: '文件大小: 36M',
        support_linux: '支持Ubuntu 16+, 其他未验证',
        tip_linux: '下载后，请给予权限，允许程序运行',
        linux_tooltip_title: 'Linux系统，如何允许程序运行？',
        linux_tooltip_body: '下载松鼠Linux版之后，右键点击文件，选择 “Properties” > “Permissions”, 勾选 “Allow executing file as program”.',
        device3: '03. 下载TV版',
        size_TV: '文件大小: 30M',
        tv_apk_blog: '教程：如何把apk文件传到智能电视上？',
        descriptionTV: '下载TV版之前，请确认您已经在松鼠VPN手机端注册了账户。仅支持在手机端注册，TV版只能登录。',
        android_not_working: 'Android成功连接后无法上网?'
    },
    buy_section: {
       title: '购买VPN服务'
    },
    support_section: {
        title: '技术支持',
        subtitle1: '联系我们',
        subtitle2: '松鼠社区',
        subtitle3: '相关教程',
        email: '电子邮件：',
        telegram1: '客服支持： ',
        telegram2: '公告通知： ',
        text: '备用下载地址：',
        faq: '常见问题',
        tutorial1: '如何切换苹果商店',
        tutorial2: '如何8分钟注册苹果ID(美国版)',
        tutorial3: '如何8分钟注册苹果ID(香港版)',
        tutorial4: 'RMB购买加密货币全教学',
    },
    payment_modal: {
        detail1: ' 专属VIP网络，更快更稳',
        detail2: ' 全球节点，不限流量',
        detail3: ' 支持4台设备同时在线',
        premium: '付费升级：',
        total: '合计：',
        select: '选择支付方式:',
        alipay: '支付宝',
        apple_pay_fee_alert: '建议使用其他支付方式，支付宝网关需要额外收取15%手续费。',
        credit: '信用卡',
        paypal: 'PayPal',
        apple_pay: 'Apple Pay',
        google_pay: 'Google Pay',
        crypto: '加密货币',
        crypto_recommend: '前往支付',
        crypto_coinpayment: '前往CoinPayment支付',
        crypto_tuturial: '【教程】还没有加密货币？购买全教程',
        crypto_tuturial_2: '【教程】使用加密货币购买松鼠VPN',
        crypto_choose: '选择加密货币种类',
        crypto_choose_2: '使用加密货币支付',
        crypto_main: '主流加密货币',
        crypto_others: '其他加密货币',
        crypto_order_timeout: "如果您已经支付，请耐心等待最多20分钟；如果您还没有支付，请返回官网，重新支付。",
        crypto_order_success: "您的订单已支付完成，可在手机App上查看VPN服务到期时间。",
        crypto_order_canceled: "订单支付超时咯，请返回官网重新支付。",
        crypto_order_timeout_title: "支付超时！",
        crypto_order_success_title: "支付成功！",
        crypto_order_canceled_title: "支付取消！",
        crypto_type: "代币类型: ",
        crypto_price: "代币价格: ",
        crypto_amount: "代币数额: ",
        address: "付款地址: ",
        time_left: "剩余时间: ",
        copy: "复制",
        crypto_steps_title: "支付步骤：",
        crypto_steps_1: "1. 使用加密货币钱包或交易所，向以上地址支付加密货币。注意货币类型和数额必须完全一致。",
        crypto_steps_2: "2. 支付完成后，请耐心等待15-25分钟，到账后，本页面会自动刷新显示支付状态，VPN服务会自动生效。您可以在手机App内查看，无需额外操作。",
        crypto_notice_title: "注意事项：",
        crypto_notice_1_1: "1. 请务必支付",
        crypto_notice_1_2: "准确代币数额，小数点后必须完全一致，",
        crypto_notice_1_3: "多付或少付，到账后都无法生效。如果发现代币数额支付错误，请联系客服。",
        crypto_notice_2_1: "2. 不支持“部分付款”，请务必",
        crypto_notice_2_2: "一次性支付完整、准确",
        crypto_notice_2_3: "的代币数额。",
        crypto_notice_3: "3. 请勿向上述地址支付任何其他类型加密货币，否则将不可找回。",
        crypto_notice_4: "4. 如遇到问题，或35分钟以上VPN购买还没有自动生效，请联系客服：",
        crypto_stable: "稳定加密货币",
        crypto_stable_subtitle: "手续费低，价格稳定"
    },
    payment_results: {
        success: '支付成功!',
        error: '后台服务器发生错误！',
        error_details: '请联系客服。',
        success_details:'您的购买将在五分钟内生效，请在手机端的松鼠VPN中确认。'
    }



};
